


@media (max-width:767px) {
  .topbarsocialicon a {
    color: #000;
    transition: all 0.5s;
    font-size: 11px;
    margin-right: 10px;
}
.text-white.topbarsocialicon {
  text-align: center;
}
.topbarcontact a {
  color: #000;
  transition: all 0.5s;
  font-size: 11px;
  margin-right: 0;
  display: block;
  text-align: center;
  margin-top: 10px;
}

.maintopbar {
  background-color: #F8F8F8;
  padding: 13px;
}

.headerlogo {
  width: 103px;
}

.hedermain {
  padding: 10px 0px !important;
}

.mainbgblue {

  display: none;
 
}

.mainbgsideimg {
  width: 100%;
}

img.mainbgsideimgelement {
  display: none;
}

.maincontentherodiv {
  padding-top: 20px;
}

.mainherotext {
  font-size: 22px;
  line-height: initial;
  color: black;
  font-weight: 700;
}
.subtextedithero {
  color: #4889f0;
  font-size: 13px;
  line-height: inherit;
  padding-top: 0;
}

.maindividercontent {
  border-top: 1px solid #ffffff5c;
  margin-top: 3vw;
  margin-bottom: 3vw;
  display: none;
}

.estimatecosttet {
  font-size: 15px;
  color: black;
  line-height: 1vw;
  margin-top: 21px;
  margin-bottom: 19px;
  font-weight: 500;
}

.mainestimcostbox {
  background: transparent;
  padding: 0;
  border-radius: 0;
}

.mainestimcostbox select.form-select {
  background-color: #1f6be2;
  color: white;
  font-size: 12px;
  padding: 10px;
  border: none;
  margin-bottom: 10px;
  border-radius: 5px;
  border-right: inherit;
  position: relative;
  background-image: none !important;
}

.dropdowniconedit {
  position: absolute;
  right: 23px;
  top: 16px;
  background-color: transparent;
  width: 10px;
}

.mainestimcostbox .form-control {
  background-color: #226ddf;
  border: none;
  color: white;
  font-size: 12px;
  padding: 10px;
}

img.serchboxmainimg {
  position: absolute;
  right: 18px;
  top: 6px;
  width: 30px;
}

.maincommonsmalltext {
  font-size: 14px;
  line-height: 1;
  color: #F9BF68;
  margin-bottom: 1.2vw;
}

.commonmainbigtitle {
  font-size: 24px;
  color: #25303F;
  line-height: inherit;
  margin-bottom: 1.2vw;
  font-weight: 500;
}

.blacktextedit {
  font-size: 13px;
  color: #4a4a4a;
}

.relaxationimgedit {
  width: 19%;
}

.rboxedittext {
  font-size: 15px;
  line-height: 1;
  color: #25303F;
  margin-top: 12px;
  font-weight: 500;
  margin-bottom: 1vw;
}

.mainrboxtextsub {
  color: #717171;
  font-size: 13px;
  font-weight: 300;
}

.mainboxrelaxtion {
  padding: 10px;
  border-right: inherit;
  margin-bottom: 28px;
}

.maincommonsmalltext2 {
  font-size: 14px;
  line-height: 1;
  color: #E2753D;
  margin-bottom: 1.2vw;
}

.whowearelineimg {
  position: absolute;
  left: 33vw;
  object-fit: cover;
  top: 13vw;
  width: 62vw;
  display: none;
}

.readmorebtn {
  color: white !important;
  color: white !important;
  font-size: 12px;
  padding: 5px 10px;
  background-color: #E2753D;
  border-radius: 5px;
}

.whoweareimgmainedit {
  width: 100%;
  margin-top: 18px;
}
img.playiconedit {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  width: 19%;
}

.mainwhowearebox {
  background-color: #1D69E5;
  padding: 20px;
  border-radius: 5px;
  margin-top: 20px;
  margin-bottom: 0px;
}

.wwrboxcount {
  font-size: 48px;
  color: #ffffff45;
  font-weight: 600;
  margin-bottom: 10px;
  line-height: 1;
}

.wwrboxtitle {
  font-size: 14px;
  color: white;
  margin-bottom: 10px;
  line-height: 1;
}
.wwrboxsubtitle {
  font-size: 12px;
  line-height: inherit;
  color: #ffffffbf;
}
img.howitworkboximg {
  width: 57px;
}
.howitboxtitle {
  font-size: 17px;
  line-height: 1;
  color: #25303F;
  margin-top: 28px;
  font-weight: 500;
  margin-bottom: 12px;
}
.mainboxhowitwork2 {
  text-align: center;
  margin-top: 33px;
  position: relative;
}
.mainboxhowitwork {
  text-align: center;
  position: relative;
  margin-top: 33px;
}
.commonmainbigtitlelight {
  font-size: 24px;
  color: #ffffff;
  line-height: inherit;
  margin-bottom: 5px;
  font-weight: 500;
}

.blacktexteditlight {
  font-size: 13px;
  color: #ffffffbf;
}
.maintestimonialdetils p {
  font-size: 14px;
  color: white;
}
.maintestimonialdetils .testimonialcheckimg {
  width: 17px;
  vertical-align: middle;
  margin-right: 10px;
  margin-top: -0.2vw;
}
img.testimonialuser {
  width: 89px;
  margin-top: -0.1vw;
  margin-right: 10px;
}
.readmorebtnew {
  color: white !important;
  color: white !important;
  font-size: 12px;
  padding: 5px 10px;
  background-color: #F9BF68;
  border-radius: 5px;
  margin-top: 4px;
  margin-bottom: 20px;
}
.testimonialstarratingmain {
  padding-left: 43px;
  margin-bottom: 20px;
}
img.testimonialuserimg {
  width: 60px;
  margin-left: -69px;
}
.maintestitmonalboxrating {
  background-color: white;
  padding: 10px 10px 10px 36px;
  border-radius: 0.5vw;
}
img.starimg {
  width: 85px;
}
.testimonialname {
  font-size: 15px;
  color: #000;
  margin-bottom: 0px;
}
.testimonialstarratingmain2 {
  padding-right: 22px;
  margin-bottom: 20px;
  padding-left: 22px;
}
img.leftshaptestimonial {
  position: absolute;
  top: 14.3vw;
  left: 0;
  width: 8vh;
  display: none;
}
img.rightshaptestimonial {
  position: absolute;
  right: -1vw;
  bottom: 3vw;
  display: none;
  width: 9vh;
}
.mainsidetextheading {
  padding-left: 0;
}

.boxpricing {
  box-shadow: 0px 0px 22px -15px grey;
  padding: 20px;
  transition: all 0.5s;
}

p.pricingtext span {
  font-size: 12px;
  color: gray;
}

p.pricingtext {
  font-size: 33px;
  color: #0F0F0F;
  font-weight: 500;
  margin-bottom: 0;
  text-align: center;
}

.plantitle {
  color: #0f0f0fa1;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}
.dividerpricing {
  border-top: 1px solid #80808047;
  margin-top: 10px;
  margin-bottom: 10px;
}
.includetext img {
  width: 13px;
  margin-top: -2px;
  margin-right: 10px;
}
p.includetext {
  font-size: 13px;
  color: black;
  margin-bottom: 10px;
}
.includetextno {
  font-size: 13px;
  color: gray;
  margin-bottom: 10px;
}
.includetextno img {
  width: 13px;
  margin-top: -2px;
  margin-right: 10px;
}
.pricingblubtn {
  background-color: #226cdc !important;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #226ddf;
  color: white !important;
  display: block;
  width: 100%;
  transition: all 0.5s;
}
.mainpricetable:hover .pricingblubtn {
  background-color: #E2753D !important;
  font-size: 12px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #E2753D;
  color: white !important;
  display: block;
  width: 100%;
}
.mainpricetable {
  margin-bottom: 20px;
}
.maintabshowwework .nav-pills .nav-link.active {
  color: #000;
  background-color: #0f334f00;
  font-size: 13px;
  font-weight: 500;
}
.maintabshowwework .nav-pills .nav-link {
  padding: 0;
  color: gray;
  font-size: 13px;
  margin-right: 17px;
}
.maintabshowwework .nav
{
display: flex;
  white-space: nowrap;
  flex-wrap: nowrap;
  overflow-x: scroll;
  padding-bottom: 18px;
}
.maintabshowwework .nav-pills .nav-link.active::after {
  content: '';
  display: block;
  width: 50px;
  height: 2px;
  background-color: #F9BF68;
  margin-top: 0.5vw;
}
img.workflowshapimg {
  position: absolute;
  right: -2.5vw;
  top: 2vw;
  width: 10vh;
  display: none;
}
.mainworkflowtext {
  background-color: #F1EAD7;
  padding: 32px 0px;
  position: relative;
  border-radius: 5px;
}
.mainworkflowtext {
  background-color: #F1EAD7;
  padding: 32px 0px;
  position: relative;
  border-radius: 5px;
}
p.workflowboxtitle {
  font-size: 14px;
  color: white;
  margin-bottom: 5px;
}
.workflowbox img {
  width: 58px;
  margin-bottom: 1.2vw;
}
p.workflowboxdetailks {
  font-size: 12px;
  color: #ffffffbf;
  margin-bottom: 0;
}
.workflowbox img {
  width: 58px;
  margin-bottom: 16px;
}
img.footershapimg {
  position: absolute;
  right: 2vw;
  top: -3vw;
  width: 8vh;
  display: none;
}
.maincontentdivfooter {
  padding-top: 50px;
}
.paddingfooterdiv {
  margin-right: inherit;
}

.footerdescription {
  font-size: 13px;
  color: #ffffffbf;
  margin-bottom: 0;
}
.footertitle {
  font-size: 16px;
  color: white;
  margin-bottom: 8px;
}
.loginbtnfooter {
  color: white !important;
  font-size: 12px;
  padding: 5px 20px;
  background-color: #E2753D;
  border: 1px solid #E2753D;
  border-radius: 5px;
  transition: all 0.5s;
  margin-top: 20px;
margin-bottom: 20px;
}
.loginbtnfooter:hover {
  color: white !important;
  font-size: 12px;
  padding: 5px 20px;
  background-color: #000000;
  border: 1px solid #000000;
  border-radius: 5px;
  margin-bottom: 20px;
}
.footerform {
  margin-bottom: 11px;
}
.footerform .form-control {
  padding: 0 0 10px 0px;
  border: 0;
  border-bottom: 1px solid #ffffff73;
  border-radius: 0;
  background-color: transparent;
  font-size: 13px;
  color: #ffffffb3;
}
a.submittextbtn {
  font-size: 14px;
  color: #ffffff;
  font-weight: 600;
  margin-bottom: 20px;
  display: block;
}
.input-groupfooter .form-control {
  background-color: #2C74EA;
  border: none;
  padding: 10px;
  font-size: 12px;
  color: white;
}
.input-groupfooter button {
  font-size: 12px;
  background-color: #dd713f;
  padding: 10px;
  border: none;
}
.input-groupfooter button:hover {
  font-size: 12px;
  padding: 10px;
  background-color: #000000;
  border: none;
}
.footerpagetext2 {
  font-size: 12px;
  color: #ffffffbf;
  margin-right: 12px;
  margin-bottom: 0;
}
.footerpagetext {
  font-size: 12px;
  color: #ffffffbf;
  margin-right: 12px;
  margin-bottom: 0;
}
.footersocialmedia {
  border-top: 1px solid #ffffff3b;
  border-bottom: 1px solid #ffffff3b;
  padding: 12px 0px;
  margin-top: 20px;
}
.footerpagetext2::before {
  content: '';
  width: 8px;
  height: 8px;
  background-color: #ffffffbf;
  display: inline-block;
  border-radius: 50px;
  margin-right: 12px;
}
.mainfooterpagetext {
  display: flex;
  justify-content: center;
}
.footersocialmediaicon i {
  font-size: 15px;
  margin-left: 23px;
  color: white;
  margin-top: 20px;
}
.footersocialmediaicon {
  text-align: center;
}
.disclamerlinefooter {
  padding: 10px;
}
.disclamerlinefooter p {
  font-size: 10px;
  text-align: center;
  color: #ffffffbf;
  margin-bottom: 0;
}
.solid-nav .nav-link {
  color: black !important;
  font-size: 14px;
  line-height: 1;
  padding-right: 0 !important;
  padding-left: 0 !important;
}
li.nav-item.navbar-linksedit {
  margin-top: 20px;
}
.solid-nav .btngetstarted {
  color: #226cdc !important;
  font-size: 12px;
  padding: 5px 20px;
  border-radius: 5px;
  border: 1px solid #226ddf;
}
.hedermain  .nav-link {
  color: black !important;
  font-size: 14px;
  line-height: 1;
  padding-right: 0 !important;
  padding-left: 0 !important;
}
li.nav-item.navbar-linksedit {
  margin-top: 20px;
}
.hedermain .btngetstarted {
  color: #226cdc !important;
  font-size: 12px;
  padding: 5px 20px;
  border-radius: 5px;
  border: 1px solid #226ddf;
}
.hedermain .navbar-nav
{
  margin-bottom: 20px;
}
.workflowbox {
  padding: 20px;
  background-color: #226cdc;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 20px;
}
}


// dashboard responshive start

@media (max-width:767px)
 {

  .registerheader {
    background-color: white !important;
    padding: 10px;
    border-bottom: 1px solid #d2d2d2;
  }

  .loginlogo {
    width: 147px;
    margin-bottom: 2vw;
}
  .registerheader .container
  {
    padding: 0;
  }
  
  // .navbar-dark .navbar-toggler {
  //   color: rgb(0 0 0);
  //   border-color: rgb(144 144 144);
  // }
  
  
  .mainboxshadow {
    box-shadow: 0px 0px 18px -11px grey;
    padding: 12px;
    margin-top: 67px;
  }
  
  .maindashboardtextedit {
    font-size: 18px;
    color: #0F0F0F;
    margin-bottom: 10px;
    font-weight: 500;
  }
  
  .mainbluboxdleft {
    background-color: #1D69E5;
    padding: 12px;
    border-radius: 10px 10px 0 0;
  }
  
  .hellomartinetext {
    font-size: 15px;
    color: white;
    font-weight: 500;
    margin-bottom: 0;
  }
  
  
  .dividerdashleft {
    border-top: 1px solid #ffffff66;
    margin-left: -12px;
    margin-right: -12px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  p.mainverifiedline {
    font-size: 13px;
    font-weight: 500;
    color: white;
    margin-bottom: 10px;
  }
  
  
  .mainverifiedbox i.las.la-envelope {
    font-size: 18px;
    vertical-align: middle;
    margin-top: -2px;
    margin-right: 10px;
  }
  
  img.verifiedimgedit {
    width: 14px;
    object-fit: cover;
  }
  
  .mainverifiedbox i.las.la-phone {
    font-size: 16px;
    margin-right: 15px;
    vertical-align: middle;
  }
  
  
  .maingrayboxedit {
    background-color: #F8F8F8;
    padding: 12px;
    border-radius: 0 0 10px 10px;
  }
  
  .membershiptext {
    margin-bottom: 10px;
    font-size: 15px;
    color: black;
    font-weight: 500;
  }
  
  .maindetailcheckmember {
    color: #292929;
    font-size: 12px;
    opacity: 0.8;
    font-weight: 500;
    margin-bottom: 8px;
  }
  
  .maindetailcheckmember i {
    color: #042956;
    margin-right: 10px;
  }
  
  
  .mainbluboxdleft2 {
    background-color: #F1EAD7;
    padding: 12px;
    border-radius: 10px;
    margin-top: 20px;
  }
  
  .hellomartinetext2 {
    font-size: 15px;
    color: black;
    font-weight: 500;
    margin-bottom: 0;
  }
  .dividerdashleft2 {
    border-top: 1px solid #9d9d9d;
    margin-left: -12px;
    margin-right: -12px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .upcomingboxedit {
    background-color: #F2E4C6;
    padding: 10px;
    display: flex;
    border: 1px solid #F9BF68;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .mainupcomingtext {
    color: #4A4A4A;
    font-size: 13px;
    font-weight: 500;
  }
  
  .mainupcomingdate {
    color: #0F0F0F;
    font-size: 13px;
    font-weight: 500;
    margin-left: 5px;
    margin-top: 1px;
  }
  
  .propertimgedit {
    object-fit: cover;
    width: 70px;
    margin-right: 10px;
    height: 70px;
    border-radius: 10px;
  }
  
  .propertynamedit {
    color: #0F0F0F;
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 5px;
  }
  
  
  .mainpropertyaddress {
    color: #4A4A4A;
    font-size: 12px;
    margin-right: 5px;
    margin-bottom: 0;
  }
  
  .mainrequestbox {
    background-color: #DCEFE5;
    padding: 12px;
    border-radius: 10px;
    margin-top: 20px;
  margin-bottom: 20px;
    position: relative;
    overflow: hidden;
  }
  
  .mainrequesttitle {
    color: #0F0F0F;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 5px;
  }
  
  .mainrequestdescription {
    font-size: 12px;
    color: #000000;
    opacity: 0.69;
    margin-bottom: 0;
  }
  .mainrequesttitleicon {
    color: #0F0F0F;
    font-size: 22px;
    margin-right: 10px;
  }
  
  img.shapleftrequest {
    position: absolute;
    left: -32px;
    bottom: -33px;
    width: 78px;
  }
  
  .shaprightrequest {
    position: absolute;
    right: -32px;
    top: -33px;
    width: 76px;
  }
  
  .mainboxshadow {
    box-shadow: none;
    padding: 0;
    margin-top: 67px;
  }
  
  
  .threeboxcontent {
    display: flex;
    padding: 12px;
    justify-content: space-between;
  }
  
  p.mainthrreboxcount {
    font-size: 25px;
    margin-bottom: 0;
    color: #12284C;
    font-weight: 600;
    margin-top: -7px;
  }
  
  p.maintitlethrrebox {
    font-size: 12px;
    margin-bottom: 0;
    font-weight: 400;
    margin-top: -4px;
    color: #4A4A4A;
  }
  
  img.mainthreeboximg {
    width: 44px;
    height: 44px;
    object-fit: cover;
    border-radius: 6px;
  }
  
  .threeboxboottomcontent {
    padding: 12px;
    text-align: center;
    border-top: 1px solid gainsboro;
  }
  
  p.textboxthreecontent {
    font-size: 14px;
    /* vertical-align: middle; */
    font-weight: 500;
    margin-bottom: 0;
  }
  
  .bootomplusicon {
    color: #0F0F0F;
    font-size: 24px;
    vertical-align: middle;
    margin-right: 4px;
    margin-top: -2px;
  }
  
  .mainboxshadowthreebox {
    box-shadow: 0px 0px 18px -13px grey;
    margin-bottom: 20px;
  }
  
  .pointfiveedit {
    padding: 12px;
    border-bottom: 1px solid gainsboro;
  }
  
  .maindashboardboxtitlecommon {
    font-size: 15px;
    color: #0F0F0F;
    font-weight: 500;
    margin-bottom: 0;
  }
  
  .mainboxespropertyedit {
    padding: 12px;
  }
  
  .mainpropertysliderimg {
    width: 70px !important;
    height: 70px;
    border-radius: 10px;
  }
  
  .propertyimgslider .carousel-item {
  width: 70px !important;
  }
  
  .propertyimgslider .carousel-inner {
  width: 70px !important;
    margin-right: 10px;
    margin-bottom: 16px;
  }
  .propertyimgslider .carousel-indicators button {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 6px;
    height: 6px;
    border-radius: 50px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #000;
    background-clip: padding-box;
    border: 0;
    opacity: 0.5;
    transition: opacity 0.6s ease;
}
  .propertyimgslider .carousel-indicators button.active {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 6px;
    height: 6px;
    border-radius: 50px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: black;
    background-clip: padding-box;
    border: 0;
    opacity: 1;
    transition: opacity 0.6s ease;
  }
  
  .propertyimgslider .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: -15px;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 1vw;
    margin-bottom: 0;
    margin-left: 0;
    list-style: none;
  }
  
  .propertyboxeditindi {
    background-color: #F7F9FD;
    padding: 12px;
    display: flex;
    border-radius: 10px;
    margin-bottom: 12px;
  }
  
  .mainpropertytitlelist {
    color: #0F0F0F;
    font-size: 13px;
    font-weight: 500;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    margin-bottom: 5px;
  }

  .propertycatageoried {
    color: #4A4A4A;
    opacity: 0.64;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 5px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
}
p.propertytextlocation {
  font-size: 12px;
  color: #4A4A4A;
  font-weight: 400;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}

img.locationpropertyedit {
  width: 11px;
  margin-right: 10px;
}

img.verifiedgreen {
  width: 13px;
}


.mainboxxshadoweditnew {
  box-shadow: 0px 0px 18px -11px grey;
  margin-top: 20px;
}



.servicelistdate {
  font-size: 12px;
  color: #0F0F0F;
  font-weight: 600;
  margin-bottom: 5px;
}
.servicelisttime {
  font-size: 12px;
  color: #0F0F0F;
  font-weight: 500;
  margin-bottom: 0;
  opacity: 0.7;
}

p.servicetitle {
  font-size: 12px;
  font-weight: 500;
  color: #0F0F0F;
  margin-bottom: 0;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}

.descriptionservicelist {
  color: #4A4A4A;
  font-size: 11px;
  margin-bottom: 0;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}

.servicetagdiv {
  background-color: #F0F0F0;
  border-radius: 5px;
  padding: 12px;
  margin-bottom: 10px !important;
  margin-top: 10px ;
}

.servicetagdiv p {
  color: #4A4A4A;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  white-space: nowrap;
  margin-bottom: 0;
}

.mainpropertysliderimg2 {
  width: 70px;
  margin-right: 10px;
  height: 70px;
  border-radius: 10px;
}

.mainpaidboxdashboard {
  border: 1px solid #1D69E5;
  border-radius: 5px;
  margin-top: 10px;
}

.paidamount {
  font-size: 12px;
  color: #0F0F0F;
  margin-bottom: 5px;
  margin-top: 5px;
  text-align: center;
}

.maindivmaintextpaidnow {
  background-color: #1D69E5;
  padding-bottom: 5px;
  padding-top: 5px;
}

.maintextpaidnow {
  font-size: 12px;
  color: white;
  margin-bottom: 0;
  text-align: center;
}

.mainboxespropertyedit .maindetaillistalldiv {
  padding-bottom: 12px;
  border-bottom: 1px solid gainsboro;
  margin-bottom: 12px;
}

.mainpaidboxdashboard2 {
  border: 1px solid #FDF2ED;
  border-radius: 5px;
  background-color: #FDF2ED;
margin-top: 10px;
}

.paidamount2 {
  font-size: 12px;
  color: #0F0F0F;
  margin-bottom: 0;
  margin-top: 5px;
  font-weight: 400;
  text-align: center;
}
.maindivmaintextpaidnow2 {
  background-color: #FDF2ED;
  padding-bottom: 5px;
  padding-top: 5px;
}

.maintextpaidnow2 {
  font-size: 12px;
  color: black;
  margin-bottom: 0;
  text-align: center;
  font-weight: 500;
}
.footercopytextedit2::before {
  content: '';
  width: 7px;
  height: 7px;
  background-color: #bcbcbc;
  display: inline-block;
  border-radius: 10px;
  margin-right: 10px;
}


.footercopytextedit2 {
  font-size: 12px;
  color: gray;
  font-weight: 300;
  margin-bottom: 0;
  margin-right: 10px;
  margin-top: 12px;
}
.footercopytextedit {
  font-size: 12px;
  color: gray;
  font-weight: 300;
  margin-bottom: 0;
  margin-right: 10px;
  margin-top: 12px;
  margin-bottom: 0px;
}

img.mainprofiledashboard {
  width: 35px;
  height: 35px;
  object-fit: cover;
  border-radius: 60px;
  margin-right: 10px;
}

.registerheader .nav-link {
  color: #4A4A4A !important;
  padding: 0 !important;
  font-weight: 500;
  margin-left: 0;
  font-size: 15px;
  margin-top: 18px;
}

.navbar-dark .navbar-toggler-icon {
  background-image: none;
  color: black;
  /* font-size: 0; */
}
.navbar-dark .navbar-toggler-icon i
{
  font-size: 32px;
}
}
// dashboard responshive end


// register profile responshive start

@media (max-width:767px) {

  .registertitleedit {
    font-size: 21px;
    color: #1D69E5;
    font-weight: 600;
    margin-bottom: 7px;
}

.registerdescription {
    color: #1d69e5a3;
    font-size: 12px;
    font-weight: 500;
}

.registerinput .form-label {
    font-size: 14px;
    color: #4A4A4A !important;
    margin-bottom: 7px;
}

.registerinput .form-control {
    border: 1px solid #cacaca;
    padding: 10px;
    font-size: 13px;
    border-radius: 5px;
}


.btnsendedit {
    padding: 10px;
    font-size: 13px;
    border: 1px solid #cacaca;
    color: #339F66;
    font-weight: 500;
}


.registerinput input[type="date"]:before {
    color: transparent;
    background: none;
    display: block;
    content: '';
   
    background-repeat: no-repeat;
    width: 28px;
    height: 28px;
    position: absolute;
    top: 10px;
    right: 0;
    color: #999;
}

.form-check-input {
    width: 12px;
    height: 12px;
    margin-top: 3px;
}

.form-check .form-check-input {
    float: left;
    margin-left: -4px;
}

.form-check-label {
  cursor: pointer;
  margin-bottom: 0;
  margin-left: 7px;
  font-size: 13px;
}
.radiolabale {
  font-size: 14px;
  color: #4A4A4A !important;
  margin-bottom: 7px;
}

.form-check {
  padding-left: 5px;
}
.form-check-inline {
  display: inline-block;
  margin-right: 15px;
}

.btnnextedit {
  font-size: 12px;
  background-color: #1D69E5;
  border: 1px solid #1D69E5;
  border-radius: 5px;
  padding: 4px 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.btnnexteditline {
  font-size: 12px;
  background-color: transparent;
  border: 1px solid #1D69E5;
  border-radius: 5px;
  color: #1D69E5;
  padding: 4px 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
}
  
img.imgprofileedit {
  width: 100%;
  height: 236px;
  object-fit: cover;
}
.btnuploadnew {
  font-size: 14px;
  background-color: #1D69E5;
  border: 1px solid #1D69E5;
  border-radius: 5px;
  padding: 5px 10px;
  margin-top: 10px;
  width: 100%;
}
}


// login responshive start

@media (min-width: 320px) and (max-width: 767px) {
//   .loginnewboxresponsive .loginboximg {
//     width: 140px;
// }

.loginboxnew.loginnewboxresponsive {
  height: auto;
  padding-top: 23px;
  padding-bottom: 23px;
}
.loginnewboxresponsive img.loginboximg {
  width: 124px;
}
.loginnewboxresponsive .loginlogo {
  width: 147px;
  margin-bottom: 23px;
}
.loginnewboxresponsive .lognmaintexteditnew {
  font-size:clamp(20px,1.7vw,1.7vw);
}
.loginnewboxresponsive .maindescriptiontextlogin {
  font-size:clamp(12px,0.85vw,0.85vw);
  font-weight: 500;
  margin-bottom: 2vw;
}
.loginnewboxresponsive .mainformlogininput .form-label {
  font-size:13px;
}
.loginnewboxresponsive .mainformlogininput .form-control {
  padding: 10px;
  font-size:11px;
  border-radius: 5px;
}
.loginnewboxresponsive .mainformlogininput {
  margin-top: 20px;
}
.loginnewboxresponsive .forgottextloginnew {
  margin-top: 10px;
  margin-bottom: 10px;
}
.loginnewboxresponsive .footerlogintext {
  font-size: 11px;
  margin-bottom: 0;
  text-align: start;
  margin-top: 5px;
}
.loginnewboxresponsive .btnloginnew {
  width: 100%;
  font-size: 13px;
  border-radius: 5px;
  padding: 10px 20px;
}
.loginnewboxresponsive .forgottextloginnew a {
  font-size: 11px;
}
.loginnewboxresponsive .copyrightnewadd{
display: block !important;
}
.loginnewboxresponsive .mainloginboxcontent {
  padding: 20px;
}
}


@media (min-width: 768px) and (max-width: 991px) {
//   .loginnewboxresponsive .loginboximg {
//     width: 140px;
// }
.loginnewboxresponsive .lognmaintexteditnew {
  font-size:clamp(20px,1.7vw,1.7vw);
}
.loginnewboxresponsive .maindescriptiontextlogin {
  font-size:clamp(12px,0.85vw,0.85vw);
  font-weight: 500;
  margin-bottom: 2vw;
}
.loginnewboxresponsive .mainformlogininput .form-label {
  font-size:13px;
}
.loginnewboxresponsive .mainformlogininput .form-control {
  padding: 10px;
  font-size:11px;
  border-radius: 5px;
}
.loginnewboxresponsive .mainformlogininput {
  margin-top: 20px;
}
.loginnewboxresponsive .forgottextloginnew {
  margin-top: 10px;
  margin-bottom: 10px;
}
.loginnewboxresponsive .footerlogintext {
  font-size: 13px;
  margin-bottom: 0;
  text-align: start;
  margin-top: 5px;
}
.loginnewboxresponsive .btnloginnew {
  width: 100%;
  font-size: 13px;
  border-radius: 5px;
  padding: 10px 20px;
}
.loginnewboxresponsive .forgottextloginnew a {
  font-size: 13px;
}

.loginnewboxresponsive .col-md-6maincolum {
  flex: 0 0 auto;
  width: 50%;
}
.loginnewboxresponsive .footercopytextedit {
  font-size: 12px;
}
.loginnewboxresponsive .footercopytextedit2 {
 font-size: 12px;
}
.loginnewboxresponsive .copyrightnewadd{
  display: block !important;
  }
  .loginnewboxresponsive .loginlogo {
    width: 20%;
}
.loginnewboxresponsive .mainloginboxcontent {
  padding: 20px;
}
}


@media (min-width: 992px) and (max-width: 1024px) {
//   .loginnewboxresponsive .loginboximg {
//     width: 140px;
// }
.loginnewboxresponsive .lognmaintexteditnew {
  font-size:clamp(20px,1.7vw,1.7vw);
}
.loginnewboxresponsive .maindescriptiontextlogin {
  font-size:clamp(12px,0.85vw,0.85vw);
  font-weight: 500;
  margin-bottom: 2vw;
}
.loginnewboxresponsive .mainformlogininput .form-label {
  font-size:13px;
}
.loginnewboxresponsive .mainformlogininput .form-control {
  padding: 10px;
  font-size:11px;
  border-radius: 5px;
}
.loginnewboxresponsive .mainformlogininput {
  margin-top: 20px;
}
.loginnewboxresponsive .forgottextloginnew {
  margin-top: 10px;
  margin-bottom: 10px;
}
.loginnewboxresponsive .footerlogintext {
  font-size: 13px;
  margin-bottom: 0;
  text-align: start;
  margin-top: 5px;
}
.loginnewboxresponsive .btnloginnew {
  width: 100%;
  font-size: 13px;
  border-radius: 5px;
  padding: 10px 20px;
}
.loginnewboxresponsive .forgottextloginnew a {
  font-size: 13px;
}

.loginnewboxresponsive .col-md-6maincolum {
  flex: 0 0 auto;
  width: 50%;
}
.loginnewboxresponsive .footercopytextedit {
  font-size: 12px;
}
.loginnewboxresponsive .footercopytextedit2 {
 font-size: 12px;
}
.loginnewboxresponsive .copyrightnewadd{
padding: 0px 15px;
}
  .loginnewboxresponsive .loginlogo {
    width: 20%;
}
}

// login responshive end

// add_new_property responsive start
@media (min-width: 320px) and (max-width: 767px) {

  .addnewpropertymaindiv .addservicetextform {
    font-size: 16px;
}
.addnewpropertymaindiv .mainsteptextform {
  font-size: 13px;
}
.addnewpropertymaindiv .maintitlereuestservicenee {
  font-size: 13px;
  margin-bottom: 10px;
}
.addnewpropertymaindiv .form-controlsustomeditnew input {
  border: 1px solid #cecece;
  color: #0f0f0f;
  padding: 13px 10px;
  border-radius:5px;
  font-size:13px ;
  margin-bottom: 10px;
}
.addnewpropertymaindiv .form-controlsustomeditnew input::placeholder {
  font-size: 13px;
}
.addnewpropertymaindiv .mainanpimg img {
  width: 100px;
  text-align: start;
  height: 100px;
  border-radius: 5px;
}
.addnewpropertymaindiv .mainanpimg {
  margin: 0px;
}
.addnewpropertymaindiv .blutextheadingeditt {
  font-size: 15px;
  
}
.addnewpropertymaindiv .form-select.form-selectnewwr {
  padding: 13px 0;
  font-size: 13px;
  padding-left: 6px;
  margin-bottom: 10px !important;
}
.addnewpropertymaindiv .mainlocationpreview {
  height: 150px;
}
.addnewpropertymaindiv .mainaddpropertybutton{
display: contents;
}
.addnewpropertymaindiv .mainfootertexteditadd{
display: block !important;
margin-bottom: 20px;
}
.addnewpropertymaindiv .mainpropertyaddvalue{
display: block !important;
 }
 .addnewpropertymaindiv .mainwritehereadd{
margin: 10px 0px;
 }
 .addnewpropertymaindiv .mainboxnewaddproperty {
  padding: 16px;
}

}
@media (min-width: 768px) and (max-width: 991px) {

  .addnewpropertymaindiv .addservicetextform {
    font-size: 16px;
}
.addnewpropertymaindiv .mainsteptextform {
  font-size: 12px;
}
.addnewpropertymaindiv .maintitlereuestservicenee {
  font-size: 13px;
}
.addnewpropertymaindiv .form-controlsustomeditnew input {
  border: 1px solid #cecece;
  color: #0f0f0f;
  padding: 13px 0px;
  border-radius:5px;
  font-size: 13px;
  padding-left: 10px;
  margin-bottom: 10px;
}
.addnewpropertymaindiv .form-controlsustomeditnew input::placeholder {
  font-size: 13px;
}
.addnewpropertymaindiv .mainanpimg img {
  width: 100px;
  text-align: start;
  height: 100px;
  border-radius: 5px;
}
.addnewpropertymaindiv .mainanpimg {
  margin: 0px;
}
.addnewpropertymaindiv .blutextheadingeditt {
  font-size: 15px;
  
}
.addnewpropertymaindiv .form-select.form-selectnewwr {
  padding: 13px 0;
  font-size: 13px;
  padding-left: 10px;
  margin-bottom: 10px !important;
}
.addnewpropertymaindiv .mainlocationpreview {
  height: 150px;
}

.addnewpropertymaindiv .mainfootertexteditadd{
display: block !important;
}

 .addnewpropertymaindiv .mainwritehereadd{
margin: 10px 0px;
 }
 .addnewpropertymaindiv .mainboxnewaddproperty {
  padding: 16px;
}
.addnewpropertymaindiv .form-check-label {
  font-size: 13px;
}
.addnewpropertymaindiv .form-check-input {
  width: 10px;
  height: 10px;
  margin-top: 4px;
}
.addnewpropertymaindiv .form-check .form-check-input {
  float: left;
  margin-left: -13px;
}
.addnewpropertymaindiv .btnnextedit {
  font-size: 13px;
  border-radius: 5px;
  padding: 6px 20px;
}
.addnewpropertymaindiv .btnnexteditline {
  font-size: 13px;
  border-radius: 5px;
  padding: 6px 20px;
}
.addnewpropertymaindiv .footercopytextedit {
  font-size: 13px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.addnewpropertymaindiv .footercopytextedit2 {
  font-size: 13px;
  margin-top: 0px;
}
.addnewpropertymaindiv .footercopytextedit2::before {
  content: '';
  width: 6px;
  height: 6px;
}
.mainfootertexteditadd{
margin-bottom: 20px;
}

}

@media (min-width: 992px) and (max-width: 1024px) {

  .addnewpropertymaindiv .addservicetextform {
    font-size: 16px;
}
.addnewpropertymaindiv .mainsteptextform {
  font-size: 12px;
}
.addnewpropertymaindiv .maintitlereuestservicenee {
  font-size: 13px;
}
.addnewpropertymaindiv .form-controlsustomeditnew input {
  border: 1px solid #cecece;
  color: #0f0f0f;
  padding: 13px 0px;
  border-radius:5px;
  padding-left: 10px;
}
.addnewpropertymaindiv .form-controlsustomeditnew input::placeholder {
  font-size: 13px;
}
.addnewpropertymaindiv .mainanpimg img {
  width: 100px;
  text-align: start;
  height: 100px;
  border-radius: 5px;
}
.addnewpropertymaindiv .mainanpimg {
  margin: 0px;
}
.addnewpropertymaindiv .blutextheadingeditt {
  font-size: 15px;
  
}
.addnewpropertymaindiv .form-select.form-selectnewwr {
  padding: 13px 0;
  font-size: 13px;
  padding-left: 6px;
}
.addnewpropertymaindiv .mainlocationpreview {
  height: 150px;
}

.addnewpropertymaindiv .mainfootertexteditadd{
display: block !important;
}

 .addnewpropertymaindiv .mainwritehereadd{
margin: 10px 0px;
 }
 .addnewpropertymaindiv .mainboxnewaddproperty {
  padding: 16px;
}
.addnewpropertymaindiv .form-check-label {
  font-size: 13px;
}
.addnewpropertymaindiv .form-check-input {
  width: 10px;
  height: 10px;
  margin-top: 4px;
}
.addnewpropertymaindiv .form-check .form-check-input {
  float: left;
  margin-left: -13px;
}
.addnewpropertymaindiv .btnnextedit {
  font-size: 13px;
  border-radius: 5px;
  padding: 6px 20px;
}
.addnewpropertymaindiv .btnnexteditline {
  font-size: 13px;
  border-radius: 5px;
  padding: 6px 20px;
}
.addnewpropertymaindiv .footercopytextedit {
  font-size: 13px;
}
.addnewpropertymaindiv .footercopytextedit2 {
  font-size: 13px;
}
.addnewpropertymaindiv .footercopytextedit2::before {
  content: '';
  width: 6px;
  height: 6px;
}
}
// add_new_property responsive end

// add_new_property responsive2 start
@media (min-width: 320px) and (max-width: 767px) {
.newpropertycontenteditadd .addservicetextform {
  font-size: 16px;
}
.newpropertycontenteditadd .mainsteptextform {
font-size: 12px;
}
.newpropertycontenteditadd .propertyimgslidernewimgedit .carousel-inner {
  height: 90px;
}
.newpropertycontenteditadd .propertyimgslidernewimgedit .carousel-inner img {
  height: 90px;
}
.newpropertycontenteditadd .deletprpertyadd {
  width: 20px;
  height: 20px;
  color: white;
}
.newpropertycontenteditadd .selectservicebackbutton {
  color: #ffff;
  font-size: 10px;
}
.newpropertycontenteditadd .maintitlereuestservicenee {
  font-size: 13px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.newpropertycontenteditadd .form-controlsustomeditnew input {
  border: 1px solid #cecece;
  font-size: 12px;
  color: #0f0f0f;
  padding: 10px 10px;
  border-radius: 5px;
  margin-top: 10px;
}
.newpropertycontenteditadd .form-controlsustomeditnew input::placeholder {
  font-size: 12px;
}
.newpropertycontenteditadd .form-select.form-selectnewwr {
  font-size: 12px;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
}
.newpropertycontenteditadd .addnewpropertytextneww {
  font-size: 12px;
}
.newpropertycontenteditadd .addnewpropertytextneww i {
  font-size: 20px;
  margin-right: 4px;
}
.newpropertycontenteditadd .deletedashimg {
  width: 20px;
}
.newpropertycontenteditadd .deletprpertyaddnewsmall {
  width: 20px;
  height: 20px;
  font-size: 13px;
}
.newpropertycontenteditadd .uploadbtnmainaddproperty {
  font-size: 12px;
  border-radius: 5px;
  padding: 8px;
  margin-top: 10px;
}
.newpropertycontenteditadd .form-group.newwform-group label {
  font-size: 12px;
  margin-bottom: 10px;
}
.newpropertycontenteditadd .uploadbtnmainaddproperty i {
  font-size: 20px;
  margin-right: 5px;
}
.newpropertycontenteditadd .maininputdefulttext p {
  font-size: 12px;
  padding-right: 10px;
}
.newpropertycontenteditadd .file-name {
  font-size: 13px;
  padding: 10px 70px 10px 73px;
  border-radius: 5px;
  margin-top: 20px;
}
.newpropertycontenteditadd .filnamewithiconmain i {
  font-size: 20px;
}
.newpropertycontenteditadd .filnamewithiconmain {
  position: absolute;
  top: 29px;
  left: 6px;
}
.newpropertycontenteditadd .maindivdeletprpertyaddnewsmall {
  position: absolute;
  right: 12px;
  top: 9px;
}
.newpropertycontenteditadd .form-group.newwform-group label:before {
  content: '';
  padding: 4px;
  border-radius:4px;
  margin-right: 5px;
}
.mainnewaddpropertycopy{
display: block !important;
}
}

@media (min-width: 768px) and (max-width: 991px) {
  .newpropertycontenteditadd .addservicetextform {
    font-size: 16px;
  }
  .newpropertycontenteditadd .mainsteptextform {
  font-size: 12px;
  }
  .newpropertycontenteditadd .propertyimgslidernewimgedit .carousel-inner {
    height: 90px;
  }
  .newpropertycontenteditadd .propertyimgslidernewimgedit .carousel-inner img {
    height: 90px;
  }
  .newpropertycontenteditadd .mainpropertytitlelist {
    font-size: 13px;
    margin-bottom: 4px;
}
.newpropertycontenteditadd .propertycatageoried {
  font-size: 13px;
    margin-bottom: 4px;
}
.newpropertycontenteditadd .selectservicebackbutton {
  color: #ffff;
  font-size: 10px;
}
.newpropertycontenteditadd .deletprpertyadd {
  width: 20px;
  height: 20px;
  color: white;
}
.maintitlereuestservicenee {
  font-size: 13px;
  margin-top: 5px;
  margin-bottom: 10px;
}
.form-controlsustomeditnew input::placeholder {
  font-size: 10px;
}
}

// add_new_property responsive2 end